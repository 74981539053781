@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
  html {
    font-family: 'Pretendard', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    color: #171719;
  }
  /*익스플로러 기본 화살표 없애기*/
  select::-ms-expand {
    display: none;
  }
  /*화살표 기본 css 없애기*/
  select {
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  body {
    background-image: url('/public/background-main1.png'), url('/public/background-main2.png');
    background-size: 100%, 100%;
    background-position: top, bottom;
    background-repeat: no-repeat, no-repeat;
  }
}
